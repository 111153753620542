<template>
<div class="main-wrapper">
    <section class="main_banner_area_winapp text-white" style="min-height: 100px">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="info">
              <h1 class="display-4 mb-3 bold">
                <i class="icon-people"></i> Contacts</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="EASY-TO-USE">
      <div class="container">
        <div class="row text-center">
          <div class="col-12 col-lg-6 col-sm-12">
            <div class="feature-right">
              <div class="section-heading text-center">
                <h2><i class="icon-basket"></i> ติดต่อฝ่ายขาย</h2>
                <div class="line-title-center"></div>
                <p>
                  สอบถามข้อมูลสินค้าและบริการ
                </p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-phone" style="float: none;"></i> โทรศัพท์</h4>
                <p>02-054-4800</p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-screen-smartphone" style="float: none;"></i> มือถือ</h4>
                <p>
                  063-464 7567, 063-464 7571
                </p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-envelope-letter" style="float: none;"></i> อีเมล์</h4>
                <p>marketing@biosoftplus.com</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-sm-12">
            <div class="feature-right">
              <div class="section-heading text-center">
                <h2><i class="icon-support" style="float: none;"></i> ติดต่อฝ่ายบริการ</h2>
                <div class="line-title-center"></div>
                <p>
                  สอบถามและขอคำแนะนำการใช้งานการแก้ปัญหาการใช้งานต่างๆ
                </p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-phone" style="float: none;"></i> โทรศัพท์</h4>
                <p>02-054-4800</p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-screen-smartphone" style="float: none;"></i> มือถือ</h4>
                <p>
                  063-464 7572, 063-464-7568
                </p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-envelope-letter" style="float: none;"></i> อีเมล์</h4>
                <p>support@biosoftplus.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2>บริการเพิ่มเติม</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <h4 style="color: #636d75;">
                    ในกรณีที่ลูกค้ากลัวติดปัญหาในการออกเงินเดือน ณ งวดนั้นๆ แล้วต้องการให้ทีมงาน Support อยู่ช่วยเหลือหลังเวลาทำการสามารถนัดหมายล่วงหน้าเพื่อขอรับบริการตรงนี้ได้เลย
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</div>
  
</template>

<script>
export default {
    name: "app",
    mounted: function() {
    this.$nextTick(function() {
      window.scrollTo(0, 0);
    });
  },
}
</script>

<style>

</style>